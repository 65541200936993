@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Poppins', sans-serif;
	background-color: #672f0a;
	color: #fff;
	line-height: 1.6;
}

p {
	font-size: 0.9rem;
	margin-bottom: 0.9rem;
}

a {
	color: #f55c0a;
	text-decoration: none;
	font-weight: bold;
}

ul {
	list-style: none;
}

h2 {
	font-size: 1.3rem;
}

h3,
h4 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	text-transform: capitalize;
}

.container {
	max-width: 600px;
	margin: auto;
	padding: 0px 20px 80px 20px;
}

header {
	height: 70px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	padding-top: 20px;
}

.headerSection {
	display: flex;
	justify-content: space-between;
	flex: 1;
	align-items: center;
}

.headerItem {
	width: 200px;
}

/*card*/
.card {
	background-color: #fff;
	color: #333;
	border-radius: 10px;
	padding: 10px 15px;
	margin: 10px 0;
	position: relative;
}

.card2Flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card-square {
	border-radius: 0px;
	padding: 20px 20px;
	margin: 5px 0;
	position: relative;
}

.card h2 {
	font-size: 22px;
	font-weight: 600;
	text-align: center;
}

.pageTitle {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgb(197, 197, 197);
	text-transform: capitalize;
}

.rating {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 30px 0 40px;
}

.rating li,
.num-display {
	position: relative;
	background: #f4f4f4;
	width: 50px;
	height: 50px;
	padding: 10px;
	text-align: center;
	border-radius: 50%;
	font-size: 19px;
	border: 1px #eee solid;
	transition: 0.3s;
}

.rating li label {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 50px;
	height: 50px;
	padding: 10px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.rating li:hover,
.num-display {
	background: #f55c0a;
	color: #fff;
}

[type='radio'] {
	opacity: 0;
}

[type='radio']:checked ~ label {
	background: #f55c0a;
	color: #fff;
}

.input-group {
	display: flex;
	flex-direction: row;
	border: 1px solid #ccc;
	padding: 8px 10px;
	border-radius: 5px;
}

input {
	flex-grow: 2;
	border: none;
}

input:focus {
	outline: none;
}

.feedback-stats {
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.num-display {
	position: absolute;
	top: -10px;
	left: -10px;
}

.close,
.edit,
.duplicate {
	position: absolute;
	top: 10px;
	right: 20px;
	cursor: pointer;
	background: none;
	border: none;
}

.edit {
	right: 100px;
}

.duplicate {
	right: 60px;
}

.btn {
	color: #fff;
	border: 0;
	border-radius: 5px;
	height: 3rem;
	cursor: pointer;
	padding-left: 11px;
	padding-right: 11px;
}

.btn-small {
	height: 1.8rem;
	background: #f55c0a;
	font-size: 0.85rem;
	margin-right: 5px;
}

.btn-medium {
	height: 2.5rem;
	font-size: 0.85rem;
	margin: 5px;
}

.btn-xsmall {
	font-size: 0.8rem;
	width: 140px;
	height: 1.4rem;
}

.btn-primary {
	background-color: #202142;
}

.btn-cancel {
	background-color: grey !important;
}

.btn-success {
	background-color: green;
}

.btn-danger {
	background-color: red !important;
}

.btn-secondary {
	background: #f55c0a;
}

.btn-block {
	background: #f55c0a;
	width: 100%;
	margin-top: 1rem;
	font-size: 1rem;
}

.btnNoMargin {
	margin-right: 0;
}

.btn:hover {
	transform: scale(0.98);
	opacity: 0.9;
}

.btn:disabled {
	background-color: #cccccc;
	color: #333;
	cursor: auto;
}

.btn:disabled:hover {
	transform: scale(1);
	opacity: 1;
}

.message {
	padding-top: 10px;
	text-align: center;
	color: rebeccapurple;
}

/* FIX: Remove position: absolute to keep about icon at the bottom of the
 * document */
.about-link {
	display: flex;
	justify-content: flex-end;
}

/* FIX: Remove position: absolute to keep about icon at the bottom of the
 * document */
.about-link a {
	color: #fff;
	cursor: pointer;
}

.about-link a:hover {
	color: #f55c0a;
}

.about h1 {
	margin-bottom: 20px;
}

.about p {
	margin: 10px 0;
}

@media (max-width: 600px) {
	.rating li {
		margin: 10px 3px;
	}

	.rating {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin: 30px 0 40px;
	}

	.input-group {
		padding-bottom: 20px;
	}

	.input-group input {
		width: 80%;
	}
}

.fluidImage {
	max-width: 100%;
	border-radius: 5px;
}

/*--Footer & Navbar--*/
footer {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #000;
	color: white;
	width: 100%;
	padding-top: 2px;
}

footer p {
	text-align: center;
	font-size: 0.7rem;
	margin-bottom: 0px;
}

.navbar {
	position: fixed;
	left: 0;
	bottom: 0px;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 14px;
}

.navbarNav {
	width: 100%;
	overflow-y: hidden;
	max-width: 600px;
}

.navbarListItems {
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.navbarListItem {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.navbarListItem p {
	font-size: 0.8rem;
}

.navbarListItemName,
.navbarListItemNameActive {
	margin-top: 0.25rem;
	font-size: 14px;
	font-weight: 600;
	color: #8f8f8f;
}
.navbarListItemNameActive {
	color: #2c2c2c;
}

/*--popup confirm--*/
.react-confirm-alert-body {
	background: #672f0a !important;
	color: #fff !important;
}

.react-confirm-alert-button-group > button {
	background-color: #f55c0a !important;
}

/*----register & login---*/
input[type='text'],
.input,
.textInput,
.select,
.textarea {
	border: none;
	color: black;
	border-radius: 5px;
	height: 3rem;
	width: 100%;
	outline: none;
	padding: 0 0.5rem;
	font-size: 0.9rem;
}

.fileInput {
	padding-top: 11px;
	background: #ffffff;
}

select {
	text-transform: capitalize;
}

@media (min-width: 1100px) {
	.input,
	.textInput,
	.textarea {
		padding: 0 1rem;
	}
}

.textarea {
	padding: 1rem 1rem;
	height: 85px;
	font-family: 'Poppins', sans-serif;
}

form label {
	padding-bottom: 1rem;
}

.signInBar,
.signUpBar {
	margin-top: 3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.signUpText {
	cursor: pointer;
}

@media (min-width: 1024px) {
	.signInBar,
	.signUpBar {
		justify-content: start;
	}
}

.inputGroup {
	margin-bottom: 1.1rem;
}

.inputGroup2cols {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}

.inputLabel {
	padding-bottom: 8px;
	font-size: 0.9rem;
	text-transform: capitalize;
}

.registerLink {
	margin-top: 2.2rem;
	margin-bottom: 2rem;
	font-weight: 600;
	text-align: center;
}

.registerLink a {
	color: white;
	text-decoration: none;
	font-size: 0.9rem;
}

.text-title {
	color: #662f0a;
	font-weight: bold;
	margin-top: 0.3rem;
	text-transform: capitalize;
	font-size: 0.95rem;
}

.text-title-smaller {
	color: #662f0a;
	font-weight: bold;
	margin-top: 0.3rem;
	text-transform: capitalize;
}

.text-display {
	text-transform: capitalize;
	margin-top: 0.3rem;
	font-size: 0.85rem;
}

.badge {
	padding: 1px 10px;
	border-radius: 5px;
	text-transform: capitalize;
	font-size: 0.8rem;
	color: white;
}

.badge-success {
	background: green;
}

.badge-info {
	color: #000;
	border: 1px solid #000;
	font-size: 0.8rem;
	font-weight: bold;
}

.badge-inactive {
	background: grey;
}

.badge-active {
	background: #0559b2;
}

.badge-danger {
	background: red;
}

.badge-warning {
	background: rgb(172, 112, 0);
}

.react-confirm-alert-button-group > button {
	font-size: 18px !important;
}

.flex-2cols {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.custom-alert {
	background: #672f0a;
	padding: 10px;
	border-radius: 5px;
	margin: 5px;
}

.custom-alert img {
	width: 100%;
	border-radius: 5px;
}

.custom-alert button {
	float: right;
}

.clear {
	border: none;
	clear: both;
}

table {
	font-size: 0.85rem;
	border-collapse: collapse;
}

td,
th {
	padding: 5px 10px;
	vertical-align: top;
	text-align: left;
}

th {
	background-color: #542101;
}

table,
td {
	border: 1px solid rgba(255, 255, 255, 0.3);
	text-transform: capitalize;
}

.alert-custom-ui {
	background: #672f0a;
	padding: 20px;
	border-radius: 5px;
	overflow-y: scroll;
}

.inputLabel2cols {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.inputGroup3cols {
	display: grid;
	grid-template-columns: 4fr 3fr 1fr;
	grid-column-gap: 10px;
	align-items: center;
}

.cellHeader {
	color: white;
	background-color: #4b1e01;
}

.spinnerBlock {
	width: 100%;
	height: 5px;
	text-align: center;
	position: relative;
	bottom: 10px;
}

.priceBorder {
	box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
	border-radius: 5px;
	padding: 2px 6px;
	font-size: 0.72rem;
	display: inline-block;
	margin-bottom: 7px;
}

::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: rgb(247, 117, 47);
	border-radius: 20px;
	border: transparent;
}

/*accordion*/
.accordion__button:hover {
	background-color: #ddd;
}
.accordion__button {
	background-color: rgb(245, 92, 10) !important;
	color: #fff !important;
	cursor: pointer;
	padding: 10px !important;
	width: 100%;
	text-align: left;
	border: none;
	border-radius: 5px;
	font-size: 0.95rem;
	margin-bottom: 10px;
}

.accordion__button:before {
	display: inline-block;
	content: '';
	height: 6px !important;
	width: 6px !important;
	margin-right: 12px;
	border-bottom: 2px solid currentColor;
	border-right: 2px solid currentColor;
	transform: rotate(-45deg);
}

.accordion__panel {
	padding: 0px !important;
	animation: fadein 0.35s ease-in;
	padding-top: 10px !important;
}

#qrcodeContainer {
	background: white;
	padding: 15px;
	padding-top: 2rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: center;
	border-radius: 10px;
}

.notifCard {
	border: 1px solid white;
	border-radius: 5px;
	padding: 5px 10px;
	margin-top: 10px;
	margin-bottom: 20px;
	color: white;
	font-size: 0.85rem;
}

.notifCardReverse {
	background-color: #f55c0a;
	border: none;
	text-align: center;
}

.swiper-slide img {
	width: 100%;
	border-radius: 5px;
}

.swiper-pagination-bullet-active {
	background-color: #000 !important;
}

.homeRecomm {
	font-size: 0.95rem;
}

.smallMargin {
	margin: 5px 0;
}

.ReactModal__Overlay {
	z-index: 2;
}
